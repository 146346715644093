<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1 " style="padding-bottom:20px">
    <div id="g2ede8" class="operations-content">
      <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
        <div>
          <span class="operations-headspan">
            Target product trade
          </span>
          <el-select style="width:  14.2857143rem;margin-left: 40px;" v-model="Importyear" placeholder="Please Select" @change="getData">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="perations-content-left">
          <span :class="activeIEports == '1'
            ? 'perations-button perations-button-active'
            : 'perations-button'
            " @click="ceckIEports('1')">Imports</span>
          <span @click="ceckIEports('2')" :class="activeIEports == '2'
            ? 'perations-button perations-button-active'
            : 'perations-button'
            ">Exports</span>
        </div>
      </h2>

      <div class="operations-box">
        <div ref="SupplyChainEchart" style="width: 100%; height: 642px"></div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>

<script>
import { tradeTrends, getTradeMapTrendData } from '@/api/companyApi.js';
import ReLogin from "../../../../components/ReLogin.vue";
export default {
  name: "",
  // props: {},
  components: {
    ReLogin,
  },
  data() {
    return {
      data: {
        links: [],
        nodes: [],
      },

      Importyear: '2024',
      Yearoptions: [
        {
          label: '2025',
          value: '2025',
        },
        {
          label: '2024',
          value: '2024',
        },
        {
          label: '2023',
          value: '2023',
        },
        {
          label: '2022',
          value: '2022',
        },
        {
          label: '2021',
          value: '2021',
        },
        {
          label: '2020',
          value: '2020',
        },
        {
          label: '2019',
          value: '2019',
        },
        {
          label: '2018',
          value: '2018',
        },
        {
          label: '2017',
          value: '2017',
        },
        {
          label: '2016',
          value: '2016',
        },
        {
          label: '2015',
          value: '2015',
        },
        {
          label: '2014',
          value: '2014',
        },
        {
          label: '2013',
          value: '2013',
        },

      ],
      datePicker: [
        ' 2024-01', '2024-12'],

      activeIEports: '1',
      importTrendListEcharts: [],
      allData:null,
      sourceData:[],


      reLoginVis: false,

    };
  },
  mounted() {
    this.getData();

  },
  methods: {
    SelectPicker() {
      this.getData()
    },
    ceckIEports(row) {

      this.activeIEports = row;
      this.getData()
    },
    default() { },
    getData() {
      getTradeMapTrendData({
        startYear: this.Importyear,
        importOrExportType: this.activeIEports,
        country: this.$route.query.id3a,
      }).then(res => {
        if (res.code == 200) {
          this.allData=res.data.allData
          this.sourceData=res.data.sourceData
          this.suppleEchart();
        }
      })

    },
    suppleEchart() {
      let that = this;
      let map = {};
      let bjdata = [];


      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);

      // 飞线图
     
      // var cityData = [
      //   { name: '中国', value: [116.20, 39.55, 10, 'China', 'United States of America',] },
      //   { name: '美国', value: [-118.24311, 34.052713, 100, 'China', 'United States of America',] },
      // ]
      var cityData = this.sourceData
      var allData = this.allData
      // var allData = {
      //   citys: [
      //     { name: '美国', value: [-118.24311, 34.052713, 100], orage: [111], },
      //     { name: '澳大利亚', value: [149.08, -35.15, 2], orage: [111] },
      //     { name: '以色列', value: [35.12, 31.47, 4], orage: [111] },
      //     { name: '巴西', value: [-48.678945, -10.493623, 4], orage: [111] },
      //   ],
      //   moveLines: [
      //     {
      //       fromName: '中国',
      //       toName: '美国',
      //       coords: [
      //         [116.20, 39.55, 10],
      //         [-118.24311, 34.052713, 5],
      //         11231
      //       ],
      //       Transactions: ' 29,093',

      //       Products: 'Mobile phones'
      //     },
      //     {
      //       fromName: '美国',
      //       toName: '巴西',
      //       coords: [
      //         [-118.24311, 34.052713, 5],
      //         [-48.678945, -10.493623, 4],

      //       ],
      //       Transactions: ' 29,093',
      //       orage: [111],
      //       Products: 'Mobile phones'
      //     },
      //     {
      //       fromName: '中国',
      //       toName: '澳大利亚',
      //       coords: [
      //         [116.20, 39.55, 10],
      //         [149.08, -35.15, 5],
      //       ],
      //       Transactions: ' 29,093',
      //       Products: 'Mobile phones'
      //     },


      //   ]

      // }
      const option = {
        backgroundColor: '#EEEEEE',
        tooltip: {
          trigger: "item",
          formatter: function (params) {

            if (params.seriesType === "scatter") {
              return `${params.marker}${params.data.name}<br/>Origin :${params.data.value[2]}`;
            } else if (params.seriesType === "lines") {
              return ` Origin: ${params.data.fromName}<br/>Destination: ${params.data.toName}<br/>Transactions : ${params.data.transactions}  `;
            } else {
              return params.name;
            }
          }
        },
        geo: {
          map: 'world',
          // aspectScale: 0.65,   // 地图长宽比
          zoom: 1,         // 初始缩放级别
          roam: false,        // 禁止拖动缩放
          label: { show: false },
          itemStyle: {
            normal: {
              borderColor: "#3EC3E3",    // 边界颜色
              borderWidth: 0,
              areaColor: "#3F6D6F",      // 地图区域颜色
              shadowColor: "#3F6D6F",    // 阴影颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0
            },
            emphasis: {
              areaColor: "#11E291",      // 高亮颜色
              borderWidth: 0.2
            }
          }
        },
        series: [
          // 基础地图层
          {
            type: "map",
            map: "world",
            roam: false,
            label: { show: false },
            itemStyle: {
              normal: {
                areaColor: "#3F6D6F",
                borderColor: "#3F6D6F",
                borderWidth: 0.2
              },
              emphasis: {
                areaColor: "#11E291"
              }
            }
          },
          // 静态图钉标记
          {
            name: '地点',
            type: 'scatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              period: 1,
              brushType: "stroke",
              scale: 8
            },
            label: {
              show: true,
              position: "right",
              color: "#fff"        // 白色标签
            },
            label: {
              normal: {
                show: false,
                position: "right",
                color: "#9966cc",
                formatter: "{b}",
                textStyle: {
                  color: "#fff"
                }
              },
              emphasis: {
                show: true
              }
            },
            symbol: 'pin',           // 图钉形状
            symbolSize: 20,

            itemStyle: {
              color: {
                type: "linear",
                colorStops: [
                  { offset: 0, color: "#00C2B0" }, // 渐变红色
                  { offset: 1, color: "#00C2B0" }
                ]
              }
            },

            data: cityData
          },
          // 动态涟漪点目标点
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 3,
            rippleEffect: {
              //涟漪特效
              period: 15, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 3, //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              normal: {
                show: false,
                position: "right", //显示位置
                offset: [5, 0], //偏移设置
                formatter: "{b}", //圆环显示文字
                textStyle: {
                  color: "#000",
                },
              },
            },
            emphasis: {
              show: true,
              scale: !0,
              label: {
                show: true,
                backgroundColor: "#000",
                padding: 2,
                borderRadius: 2,
                color: "#fff",
              },
            },
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                show: false,
                color: "#0FD1D9",
              },
            },
            data: allData.citys
          },
          // 连接线
          {
            type: "lines",
            coordinateSystem: "geo",
            zlevel: 2,
            large: true,
            // effect: {
            //   show: true,
            //   symbol: "arrow",      // 箭头图标
            //   symbolSize: 7,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
            //   trailLength: 0.1,
            //   period: 10           // 箭头移动速度
            // },
            lineStyle: {
              color: "#17B5AE",    // 青蓝色
              width: 1.5,
              curveness: 0.3       // 线条曲度
            },
            label: {
              normal: {
                show: false,
                position: 'middle',
                formatter: '{b}'
              }
            },
            data: allData.moveLines
          }
        ]
      };
      this.echartsBox.setOption(option);
      this.$nextTick(() => {
        this.echartsBox.resize();
      })

    },
  },
  computed: {},

};
</script>
<style scoped>
.operations-content {
  padding: 30px 40px;
  height: 100%;
}

.operations-head {

  /* font-family: "Arial Bold"; */


  display: flex;
  justify-content: space-between;
}

.operations-headspan {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
}

.operations-content {
  padding: 30px 40px;
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.table-content-color {
  color: #009EA7;
}

.perations-button-active {
  background: #ffffff !important;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem;
  color: #009EA7 !important;
  cursor: pointer;
}




/deep/ .el-range-editor.el-input__inner {
  padding: 0px !important;
}

/*cssStart*/

/*cssEnd*/
</style>